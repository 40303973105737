import Card from 'components/Card';
import { Container } from '@material-ui/core';
import createMarginPaddingStyles from 'utilities/createMarginPaddingObject';
import './style.scss';

export default function TemporaryContainer(props) {
	const { marginAndPadding } = props;
	
	return (
		<div className='single-card-container'  style={createMarginPaddingStyles(marginAndPadding)}>
			<Container maxWidth='md' className='temporary-card-inner-container'>
				<Card {...props.cards[0]} />
			</Container>
		</div>
	);
}

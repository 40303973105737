const dependenciesProptotype = {
	current: {
		pushMenuReference: () => {},
		setExpandMenu: () => {},
		onBack: () => {},
		queryType: 'persistantNav',
		social: {},
		subtitle: {},
	},
};
export default dependenciesProptotype;
